import React from "react";
import "./Loader.css";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="loader-container">
        <img
          src="Eqresearch_Logo.png"
          alt="Gear Loader"
          style={{ width: "250px" }}
        />
      </div>
    );
  };
}

export default Loader;
